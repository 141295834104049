import { ChangeDetectorRef, DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import { isLocalizedString, LocalizedString } from '@cue/api';
import { TranslocoService } from '@ngneat/transloco';
import { localized } from '@cue/translate';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Pipe({
  standalone: true,
  name: 'cmLocalizedString',
  pure: false,
})
export class CmUnityLocalizedStringPipe implements PipeTransform {
  private destroyRef = inject(DestroyRef);
  private readonly reset$ = new Subject<void>();
  private cdr = inject(ChangeDetectorRef);
  __value?: string;
  __oldKey?: string | LocalizedString;
  translocoService = inject(TranslocoService);

  transform(key?: LocalizedString | string): string {
    if (!key) return key as unknown as string;

    if (!isLocalizedString(key)) {
      this.__value = key;
    }
    if (this.__oldKey !== key) {
      this.__oldKey = key;
      this.reset$.next();
      this.translocoService.langChanges$
        .pipe(
          takeUntil(this.reset$),
          map(() => this.translocoService.getActiveLang()),
          takeUntilDestroyed(this.destroyRef),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          const oldValue = this.__value;
          this.__value = localized(key, this.translocoService, false) || key['default'];
          if (oldValue !== this.__value) {
            this.cdr.markForCheck();
          }
        });
    }

    return this.__value;
  }
}
