export function mixColors(color1, color2, ratio) {
  // Clamp ratio to the range [0, 1]
  ratio = Math.max(0, Math.min(1, ratio));

  // Parse RGBA colors
  function parseRGBA(color) {
    const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/);
    if (!match) {
      throw new Error(`Invalid color format: ${color}`);
    }
    return {
      r: parseInt(match[1], 10),
      g: parseInt(match[2], 10),
      b: parseInt(match[3], 10),
      a: parseFloat(match[4] || 1),
    };
  }

  // Linearly interpolate between two values
  function lerp(a, b, t) {
    return a + (b - a) * t;
  }

  const c1 = parseRGBA(color1);
  const c2 = parseRGBA(color2);

  // Blend colors
  const r = Math.round(lerp(c1.r, c2.r, ratio));
  const g = Math.round(lerp(c1.g, c2.g, ratio));
  const b = Math.round(lerp(c1.b, c2.b, ratio));
  const a = lerp(c1.a, c2.a, ratio);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
