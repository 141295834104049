import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonHttpService } from './common-http.service';
import { AttendeeBase, User, MeetingTimeSuggestionsResult, Message } from '@microsoft/microsoft-graph-types';

export interface ILocationDetail {
  displayName: string;
  locationEmailAddress: string;
}

export interface ILocationConstraint {
  isRequired: boolean;
  suggestLocation: boolean;
  locations: ILocationDetail[];
}

export interface ITimeslotDetail {
  dateTime: Date;
  timeZone: 'Pacific Standard Time' | 'UTC';
}

export interface ITimeslot {
  start: ITimeslotDetail;
  end: ITimeslotDetail;
}

export interface ITimeConstraint {
  timeslots: ITimeslot[];
}

export interface IMeetingServerFilter {
  attendees: AttendeeBase[];
  timeConstraint: ITimeConstraint;
  locationConstraint: ILocationConstraint;
  meetingDuration: 'PT1H';
}
export interface IMeetingTimeFilter {
  startDate: Date;
  endDate: Date;
  colleagueList: string[];
}

@Injectable({
  providedIn: 'root',
})
export class DashboardHttpService {
  constructor(protected http: CommonHttpService) {}

  loadSummaryOverview(): Observable<User> {
    const url = `https://graph.microsoft.com/v1.0/me`;

    return this.http.get<any, any>(url, (x: any) => {
      return x;
    });
  }

  getEmails(): Observable<Message[]> {
    const url = `https://graph.microsoft.com/v1.0/me/messages`;

    return this.http.get<any, any>(url, (x: any) => {
      return x.value;
    });
  }

  private formatTimeWithNoZone(date: Date): Date {
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }

  private getFilterJson(filter: IMeetingTimeFilter): IMeetingServerFilter {
    return {
      attendees: filter.colleagueList.map((x) => {
        return {
          emailAddress: {
            name: x,
            address: x,
          },
          type: 'required',
        };
      }),
      meetingDuration: 'PT1H',
      locationConstraint: {
        suggestLocation: true,
        isRequired: false,
        locations: [],
      },
      timeConstraint: {
        timeslots: [
          {
            start: {
              dateTime: this.formatTimeWithNoZone(filter.startDate),
              timeZone: 'UTC',
            },
            end: {
              dateTime: new Date(filter.endDate.getTime() - filter.endDate.getTimezoneOffset() * 60000),
              timeZone: 'UTC',
            },
          },
        ],
      },
    };
  }

  findMeetingTime(filter: IMeetingTimeFilter): Observable<MeetingTimeSuggestionsResult> {
    const url = `https://graph.microsoft.com/v1.0/me/findMeetingTimes`;

    return this.http.post<any, any>(url, this.getFilterJson(filter), (x: any) => {
      return x;
    });
  }
}
