import { Inject, Injectable, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

export const CLIENT_ID: InjectionToken<string> = new InjectionToken<string>('CUE_CLIENT_ID');

export interface ControllableObjectUpdateData {
  objectId: string;
  data: any;
}

@Injectable()
export class SignalRService {
  // dataFromSignalR$: BehaviorSubject<any> = new BehaviorSubject(null);
  //
  // chatMessageList: string[] = [];
  // chatMessageList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  controllableObjectUpdateData$: BehaviorSubject<ControllableObjectUpdateData> = new BehaviorSubject(null);
  forceReloadPoiData$: BehaviorSubject<string> = new BehaviorSubject(null);
  forceReloadArea$: BehaviorSubject<number> = new BehaviorSubject(null);

  private hubConnection: signalR.HubConnection;

  constructor(
    @Inject(CLIENT_ID) private clientId: string,
    private apiService: ApiService,
  ) {}

  public startConnection = () => {
    const url = this.apiService.getUrl('/MessageHub');

    if (this.hubConnection?.state === HubConnectionState.Connected) {
      console.log('ALREADY CONNECTED!!!');
      return;
    } else {
      console.log('START CONNECTION ON URL: ', url);
    }

    const apiToken = localStorage.getItem('api_token');
    if (apiToken) {
      //TODO: Jakub pohandlit refresh tokenu
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory: () => apiToken })
        .withAutomaticReconnect()
        .build();
    } else {
      this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
    }

    this.hubConnection
      .start()
      .then(() => console.log('SignalR connection started'))
      .catch((err) => console.log('Error while starting SignalR connection: ' + err));
  };

  public addTransferDataListener = () => {
    this.hubConnection.on('Reload', (qrCode) => {
      // console.log(qrCode);
      this.forceReloadPoiData$.next(qrCode);
    });

    this.hubConnection.on('reload-area', (areaId) => {
      console.log(areaId);
      this.forceReloadArea$.next(areaId);
    });

    this.hubConnection.on('ObjectChanged', (objectId, message) => {
      this.controllableObjectUpdateData$.next({
        objectId: objectId,
        data: JSON.parse(message),
      });
    });
  };

  public sendMessageSignalR = (objectId: string, itemId: string) => {
    if (this.hubConnection?.state === HubConnectionState.Connected) {
      const message = `OBJECT: ${objectId} - ITEM: ${itemId}`;
      // console.log(message);
      this.hubConnection.invoke('Object', objectId, itemId).catch((err) => console.error(err));
    } else {
      // this.startConnection();
      // this.sendMessageSignalR(objectId, itemId);
    }
  };
}

export function provideSignalRService(options: { factory: () => string }) {
  return makeEnvironmentProviders([
    {
      provide: CLIENT_ID,
      multi: false,
      useFactory: options.factory,
    },
    SignalRService,
  ]);
}
