import { Injectable } from '@angular/core';

export type FirstDayOfWeek = 'monday' | 'sunday';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor() {}
  static availableCodes = {
    fr: () => 'fr',
    // ar: () => 'ar',
    no: () => 'nb',
    nb: () => 'nb',
    en: () => 'en',
    cs: () => 'cs',
    ca: () => 'ca',
    ua: () => 'ua',
    // de: () => 'de',
    es: () => 'es',
    // pl: () => 'pl',
    // it: () => 'it',
    // pt: () => 'pt',
  };

  static FIRST_DAY_OF_WEEK_DAY_KEY = 'ASSIST_FIRST_DAY_OF_WEEK';

  getFirstDayOfWeek(): FirstDayOfWeek {
    return (localStorage.getItem(LocaleService.FIRST_DAY_OF_WEEK_DAY_KEY) as FirstDayOfWeek) || 'monday';
  }

  setFirstDayOfWeek(day: FirstDayOfWeek) {
    localStorage.setItem(LocaleService.FIRST_DAY_OF_WEEK_DAY_KEY, day);
  }

  getLocaleId() {
    const userLang = navigator.language || (navigator as any).userLanguage;
    const code = userLang.split('-')[0];
    const foundCode = LocaleService.availableCodes[code];
    const returnCode = foundCode ? foundCode() : 'en';

    return returnCode;
  }
}
