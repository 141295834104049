import { Injectable } from '@angular/core';
import { Config, ResourceDetail } from '../models';
import { AssistConfiguration, AssistConfigurationBM, AssistResourceBM, fromLocalized, LocalizedString, ServerType } from '@cue/api';
import { CalendarAvailability } from '@cue/calendars';
import { Memoize } from 'typescript-memoize';
import { LicensingService } from '@cue/licensing';
import { distinctBy, mixColors } from '../utils';
import { localized } from '@cue/translate';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigurationCache, assistDB } from './assistDB';

type Configuration = AssistConfigurationBM &
  Config & {
    scope: string;
    redirectUri: string;
  };

@Injectable()
export class ConfigService {
  private CURRENT_VERSION = 48;
  private _config: Configuration;

  backgroundColor(availability: CalendarAvailability): string {
    switch (availability) {
      case CalendarAvailability.free:
        return this.value.design.background.reservableResource.free;
      case CalendarAvailability.reserved:
        return this.value.design.background.reservableResource.reserved;
      case CalendarAvailability.freeOutsideWorkingHours: {
        const rgbaString = this.value.design.background.reservableResource.free;
        const r = parseInt(rgbaString.substring(5, 8));
        const g = parseInt(rgbaString.substring(9, 13));
        const b = parseInt(rgbaString.substring(15, 18));
        return mixColors(this.value.design.background.reservableResource.free, 'rgba(255,255,255,0.59)', 0.5);
      }
      case CalendarAvailability.closed:
        return this.value.design.background.reservableResource.closed;
      case CalendarAvailability.limited:
        return this.value.design.background.reservableResource.limited;
      case CalendarAvailability.tentative:
        return this.value.design.background.reservableResource.tentative;
      case CalendarAvailability.assignedToOthers:
        return this.value.design.border.assignedToOther;
      case CalendarAvailability.assignedToYou:
        return this.value.design.border.assignedToYou;
      case CalendarAvailability.maxUtilization:
        return this.value.design.background.reservableResource.limited;
      case CalendarAvailability.currentEvent:
        return 'rgba(35, 60, 90)';
      default:
        return this.value.design.background.reservableResource.tentative;
    }
  }

  getMode(): ServerType {
    return this.value.serverType;
  }

  isO365(): boolean {
    return this.getMode() === 'o365';
  }

  isGspace(): boolean {
    return this.getMode() === 'gspace';
  }

  isEWS(): boolean {
    return this.getMode() === 'ews';
  }

  isTouchoneCalendar(): boolean {
    return this.getMode() === 'touchone-calendar';
  }

  prebootstrapLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      let cachedConfiguration: ConfigurationCache;
      request.addEventListener('error', (e) => {
        reject('Cannot load config file.');
      });
      request.addEventListener('load', (_) => {
        if (request.status === 200) {
          try {
            const config = JSON.parse(request.responseText) as Config;
            const request2 = new XMLHttpRequest();
            request2.addEventListener('error', (e) => {
              reject('Cannot connect to API server, check if it is available');
            });
            request2.addEventListener('load', () => {
              if (request2.status !== 200 && request2.status !== 204) {
                reject(request2.statusText);
              }
              let response: AssistConfiguration = null;
              if (request2.status === 204) {
                response = JSON.parse(cachedConfiguration.data) as AssistConfiguration;
              }

              if (request2.status === 200) {
                try {
                  let cachedHeader = undefined;
                  try {
                    cachedHeader = request2.getResponseHeader('CachedConfigurationTimeStamp');
                  } catch {
                    cachedHeader = null;
                  }
                  if (cachedHeader) {
                    cachedConfiguration = {
                      id: 1,
                      data: request2.responseText,
                      timestamp: cachedHeader,
                      version: this.CURRENT_VERSION,
                    };
                    assistDB.configurations.put(cachedConfiguration, 1);
                    response = JSON.parse(cachedConfiguration.data) as AssistConfiguration;
                  } else {
                    assistDB.configurations.clear();
                    response = JSON.parse(request2.responseText);
                  }
                } catch (e) {
                  reject(e);
                }
              }
              if (response == null) {
                assistDB.configurations.clear();
                console.error('Spatna konfigurace na serveru, /api/assist/configurations nevratil zadna data!');
                reject('API is not configured. Edit the settings in CUE Admin.');
                return;
              }

              if (!response.assistConfigured) {
                // http://localhost:4201/qr/eaceec29-eb29-4390-833f-3b59f45c5001
                if (response.navigationConfigured && response.navigationUrl && location.pathname.includes('qr/')) {
                  const qrRequest = new XMLHttpRequest();
                  const pathNames = location.pathname.split('/');
                  const id = pathNames[pathNames.length - 1];
                  const qrUrl = config.apiURL + '/api/qrcode/info/' + id;

                  qrRequest.addEventListener('error', (e) => {
                    reject('Cannot load QR Code info file.');
                    return;
                  });
                  qrRequest.addEventListener('load', (_) => {
                    if (qrRequest.status === 200) {
                      const response: any = JSON.parse(qrRequest.responseText);
                      const hasLocation = response.data?.hasLocation;
                      const startAreaId = response.data?.startAreaId;
                      const qrCodeId = response.data?.qrId;

                      if (hasLocation) {
                        window.location.href = response.data.navigationUrl + '?startAreaId=' + startAreaId + '&locationId=' + qrCodeId;
                        return;
                      } else {
                        reject('cannot load qr code');
                        return;
                      }
                    } else {
                      reject('Assist is not configured. Edit the settings in CUE Admin.');
                      return;
                    }
                  });
                  qrRequest.open('GET', qrUrl);
                  qrRequest.send();
                } else {
                  assistDB.configurations.clear().then(() => {
                    reject('Assist is not configured. Edit the settings in CUE Admin');
                  });
                }
                return;
              }

              const configuration = response.asistConfiguration;
              this._config = {
                ...configuration,
                apiURL: config.apiURL,
                calendarURL: config.calendarURL,
                redirectUri: location.origin + '/account/oauth-callback',
                scope:
                  configuration.serverType === 'gspace'
                    ? 'openid email profile  https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                    : 'offline_access User.ReadBasic.All openid profile email Calendars.Read.Shared Calendars.ReadWrite Calendars.ReadWrite.Shared Calendars.Read Contacts.Read Contacts.Read.Shared ' +
                      (configuration.enableGroupFinding ? 'Group.Read.All' : ''),
              };

              resolve(null);
            });
            let configurationUrl = config.apiURL + '/api/assist/configurations';

            assistDB.configurations.get(1).then((cache) => {
              if (!!cache && cache.version >= this.CURRENT_VERSION) {
                // Je nacachovano
                cachedConfiguration = cache;
                configurationUrl = configurationUrl + '?cachedTimestamp=' + encodeURIComponent(cache.timestamp);
              }
              request2.open('GET', configurationUrl);
              request2.send();
            });
          } catch (e) {
            assistDB.configurations.clear().then(() => {
              reject(e);
            });
          }
        } else {
          reject(request.statusText);
        }
      });
      request.open('GET', '/config.json');
      request.send();
    });
  }

  get value(): Configuration {
    if (!this._config)
      throw Error('Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?');
    return this._config;
  }

  getResourceTypeFromId(resourceTypeId: number) {
    return this.value.resourceTypeInfos.find((x) => x.resourceTypeId === resourceTypeId);
  }

  getFiltersFromResource(resource: AssistResourceBM | ResourceDetail): {
    name: LocalizedString;
    value: any;
    dataTypeId: number;
    filterable: boolean;
    visible: boolean;
    propertyId: string;
  }[] {
    const resourceTypeId = resource.resourceTypeId;
    const filters = this.value.filters.filter((x) => x.resourceTypeId === resourceTypeId);
    const computed = filters.map((filter) => {
      const properties = resource.properties.filter((property) => property.propertyId === filter.id);
      let value: any = null;
      switch (filter.dataTypeId) {
        case 1: {
          value = properties.length > 0 ? properties[0].valueBoolean : null;
          break;
        }
        case 2: {
          value = properties.length > 0 ? properties[0].valueNumber : null;
          break;
        }
        case 3: {
          value = properties.length > 0 ? properties[0].valueDecimal : null;
          break;
        }
        case 4: {
          value = properties.length > 0 ? (properties[0].valueText ?? '') : null;
          break;
        }
        case 6: {
          value = properties.length > 0 ? properties[0].valueHTMLContent : null;
          break;
        }
        case 5: {
          value = filter.choices
            .filter((choice) => properties.find((p) => p.valueChoiceIds.find((vch) => vch === choice.key)) != null)
            .map((x) => ({
              title: x.value,
              imageUrl: x.imageUrl,
              imagePreferCover: x.imagePreferCover,
            }));
          break;
        }
        default:
          value = null;
          break;
      }

      return {
        name: filter.name,
        value: value,
        visible: filter.visible,
        filterable: filter.filterable,
        dataTypeId: filter.dataTypeId,
        propertyId: filter.id,
      };
    });

    return computed.filter((c) => !Array.isArray(c.value) || c.value.length > 0);
  }

  @Memoize((resources: AssistResourceBM[], resourceTypeId: number, activeLang: string, defaultLang: string) => {
    return resourceTypeId + activeLang + defaultLang;
  })
  getFilterableResourcesFilteredByResourceTypeId(resources: AssistResourceBM[], resourceTypeId, activeLang: string, defaultLang: string) {
    return this.getFilterableResources(resources, activeLang, defaultLang).filter((x) => x.resourceTypeId == resourceTypeId);
  }

  @Memoize((resources: AssistResourceBM[], resourceTypeId: number) => {
    return resourceTypeId;
  })
  getFilterableResourcesForCombo(resources: AssistResourceBM[], activeLang: string, defaultLang: string) {
    const allData = resources
      .map((x) => ({
        ...x,
        filterName:
          fromLocalized(x.displayNameForApp, activeLang, defaultLang) ?? fromLocalized(x.displayName, activeLang, defaultLang) ?? x.name,
      }))
      .sort((a, b) => a.filterName.localeCompare(b.filterName));
    return distinctBy(allData, (x) => x.idForFiltering);
  }

  @Memoize((resources: AssistResourceBM[], resourceTypeId: number, activeLang, defaultLang) => {
    return resourceTypeId + activeLang + defaultLang;
  })
  getFilterableResources(resources: AssistResourceBM[], activeLang: string, defaultLang: string) {
    return resources
      .filter((x) => this.isRestrictedResourceVisible(x))
      .map((x) => ({
        ...x,
        filterName: fromLocalized(x.displayNameForApp, activeLang, defaultLang) ?? x.name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  isRestrictedResourceVisible(resource: AssistResourceBM) {
    return (
      this.value.resourceVisibilityRestriction == 1 || resource.conditions.reservedTo == null || resource.conditions.reservedTo == 'you'
    );
  }

  getEnumerableFiltersByResourceTypeId(resource: AssistResourceBM | ResourceDetail) {
    return this.getFiltersFromResource(resource).filter((x) => x.dataTypeId === 5);
  }

  getSimpleFiltersByResourceTypeId(resource: AssistResourceBM | ResourceDetail, translocoService: TranslocoService) {
    return this.getFiltersFromResource(resource).filter((x) => {
      const property = this.value.filters.find((y) => y.id === x.propertyId);
      const isNotEnumerable = x.dataTypeId !== 5;
      const textHtmlNotEmpty =
        (x.dataTypeId !== 4 && x.dataTypeId !== 6) ||
        (localized(x.value, translocoService) != null && localized(x.value, translocoService) !== '');
      return isNotEnumerable && textHtmlNotEmpty;
    });
  }

  getFilteredResourceTypes(resources: AssistResourceBM[], licenseService: LicensingService) {
    let groups = [];
    if (this.value.resourceVisibilityRestriction == 1) {
      groups = this.value.resourceTypeInfos.filter((resourceType) => {
        return (
          resources.find(
            (r) => r.resourceTypeId == resourceType.resourceTypeId && licenseService.availableForServerType(r, this.getMode()),
          ) != null
        );
      });
    } else {
      groups = this.value.resourceTypeInfos.filter((resourceType) => {
        return (
          resources.find(
            (resource) =>
              this.isRestrictedResourceVisible(resource) &&
              licenseService.availableForServerType(resource, this.getMode()) &&
              resource.resourceTypeId == resourceType.resourceTypeId,
          ) != null
        );
      });
    }
    return groups.sort((a, b) => a.rank - b.rank);
  }
}
