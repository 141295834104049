import { PackedAreas } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as ControllableObjectsActions from '../actions/controllable-objects.actions';
import { ControllableObjectsModel } from '../models/app/controllable-objects';

export const initialState: ControllableObjectsModel = {
  currentControllableObject: null,
  controllableObjectList: [],
};

const reducer = createReducer(
  initialState,
  on(ControllableObjectsActions.loadControllableObjects, (state) => ({
    ...state,
    controllableObjectList: [],
  })),
  on(ControllableObjectsActions.controllableObjectsLoaded, (state, { objectList }) => ({
    ...state,
    controllableObjectList: objectList,
  })),
);

export function controllableObjectsReducer(state: ControllableObjectsModel, action: Action) {
  return reducer(state, action);
}
