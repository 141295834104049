import { LocalizedString } from '../common';

export interface Message {
  type: ControllableObjectMessageType;
  iconUrl: string;
  text: LocalizedString;
}

export interface Item {
  id: string;
  type: string;
  state?: string;
  indication?: string;
  iconUrl?: string;
  label?: LocalizedString;
  value?: LocalizedString;
  valueType?: string;
  unit?: LocalizedString;
  repeatButtonInterval?: number;
}

export interface Group {
  id: string;
  label: LocalizedString;
  message: Message;
  item: Item[];
}

export interface MonitorItem {
  id: string;
  iconUrl?: string;
  label?: LocalizedString;
  value?: LocalizedString;
  unit?: LocalizedString;
}

export enum ControllableObjectMessageType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum ControllableObjectType {
  Control = 'control-1',
  Monitor = 'monitor-1',
}

export interface ControlableObjectBasicInfo {
  id: string;
  name: string;
  type: ControllableObjectType;
}

export interface ControllableObjectMapItem {
  name: string;
  objectId: string;
  data: ControllableObjectDetail;
}

export interface ControllableObjectDetail {
  objectId: string;
  name: string;
  dataFromUnitAvailable: boolean;
  type?: ControllableObjectType;
  version?: number;
  label?: LocalizedString;
  iconUrl?: string;
  //TODO:Jakub rozdelit objektove
  //item pouze pro type Control
  message?: Message;
  //TODO:Jakub rozdelit objektove
  //item pouze pro type Control
  group?: Group[];
  //TODO:Jakub rozdelit objektove
  //item pouze pro type Monitor
  item?: MonitorItem[];
}
